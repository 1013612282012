import { useDeviceInformation } from '../../../../hooks/useDeviceInformation';

export const Footer: React.FC = () => {
  const { deviceInformation } = useDeviceInformation();

  return !deviceInformation.isWebView ? (
    <footer
      className="tw-h-[12rem] tw-bg-cover tw-bg-[top_center] tw-bg-no-repeat"
      style={{
        backgroundImage: 'url(/images/footer_background.svg)',
      }}
    />
  ) : null;
};
