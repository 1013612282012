import Link from 'next/link';
import IconAccount from '../../../../../assets/icon/account.svg';
import IconHelp from '../../../../../assets/icon/help.svg';
import IconPoint from '../../../../../assets/icon/point.svg';
import UnextLogo from '../../../../../assets/images/logo.svg';
import type { Config } from 'u-next/config';

export const MobileMenuNavigation: React.FC<{
  isExpanded: boolean;
  options: Config;
}> = ({ isExpanded, options }) => (
  <div
    className={
      isExpanded
        ? 'tw-fixed tw-inset-y-0 tw-right-0 tw-w-[16rem] tw-translate-x-0 tw-transition-transform'
        : 'tw-fixed tw-inset-y-0 tw-right-0 tw-w-[16rem] tw-translate-x-[100%] tw-transition-transform'
    }
  >
    <div className="tw-absolute tw-inset-0 tw-bg-base-100" />
    <nav className="tw-relative tw-pt-[5rem]">
      <a href={options.VIDEO} className="tw-mx-auto tw-block tw-w-[8rem]">
        <UnextLogo />
      </a>
      <div className="tw-custom-side-space tw-mt-[4rem] ">
        <ul className="tw-grid tw-gap-[0.5rem]">
          {[
            {
              url: `${options.MYACCOUNT}/unext-point`,
              text: 'U-NEXTポイント',
              icon: <IconPoint />,
            },
            {
              url: `${options.MYACCOUNT}/settings`,
              text: 'アカウント・契約',
              icon: <IconAccount />,
            },
            {
              url: options.HELP,
              text: 'ヘルプ',
              icon: <IconHelp />,
            },
          ].map(({ url, text, icon }) => (
            <li key={url}>
              <Link href={url} passHref>
                <div className="tw-flex tw-h-[3rem] tw-items-center tw-gap-[0.5rem]">
                  <div className="tw-relative tw-h-full tw-w-[2.5rem]">
                    <div className="tw-absolute tw-inset-0 tw-m-auto tw-h-[1.5rem] tw-w-[1.5rem] [&_svg]:tw-h-[1.5rem] [&_svg]:tw-w-[1.5rem]">
                      {icon}
                    </div>
                  </div>
                  <div className="tw-text-base1Title tw-text-main-100">
                    {text}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  </div>
);
