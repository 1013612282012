export const MobileMenuBackGroundFilter: React.FC<{
  isExpanded: boolean;
  handleOnClick: () => void;
}> = ({ isExpanded, handleOnClick }) => (
  <div className={isExpanded ? 'tw-visible' : 'tw-invisible'}>
    <div
      className="tw-fixed tw-inset-0 tw-bg-main-40"
      tabIndex={0}
      role="button"
      onClick={handleOnClick}
    />
  </div>
);
