import Logo from '../../../../../assets/images/logo.svg';

const getLogo = (
  isYamadaPF: boolean,
  otherFunctionId: string
): React.ReactNode => {
  if (isYamadaPF) {
    return (
      <img
        className="tw-block tw-h-full tw-w-full tw-object-contain tw-object-center"
        src="//metac.nxtv.jp/img/wf/logo/yamada.png"
        alt="U-NEXT"
      />
    );
  }

  switch (otherFunctionId) {
    case '600':
    case '700':
    case '701':
      return (
        <img
          className="tw-block tw-h-full tw-w-full tw-object-contain tw-object-center"
          src={`//metac.nxtv.jp/img/wf/logo/unext${otherFunctionId}.png`}
          alt="U-NEXT"
        />
      );

    default:
      return (
        <Logo className="tw-absolute tw-inset-y-0 tw-my-auto tw-block tw-w-[8rem]" />
      );
  }
};

export const SiteLogo: React.FC<{
  videoUrl: string;
  isYamadaPF: boolean;
  otherFunctionId: string;
}> = ({ videoUrl, isYamadaPF, otherFunctionId }) => (
  <a
    href={videoUrl}
    className="tw-absolute tw-inset-y-0 tw-left-[1rem] tw-w-[10rem]"
  >
    {getLogo(isYamadaPF, otherFunctionId)}
  </a>
);
