import { atom, useAtom } from 'jotai';
import React from 'react';
import UaParser from 'ua-parser-js';

type DeviceInformation = {
  os: string;
  osVersion: string;
  userAgent: string;
  browserName: string;
  browserVersion: string;
  isLoaded: boolean;
  isWebView: boolean;
  isIOS: boolean;
  isAndroid: boolean;
};

const deviceInformationAtom = atom<DeviceInformation>({
  os: '',
  osVersion: '',
  userAgent: '',
  browserName: '',
  browserVersion: '',
  isLoaded: false,
  isWebView: false,
  isIOS: false,
  isAndroid: false,
});

type UseDeviceInformation = {
  deviceInformation: DeviceInformation;
};

let initialized = false;

export const useDeviceInformation = (): UseDeviceInformation => {
  const [deviceInformation, setDeviceInformation] = useAtom(
    deviceInformationAtom
  );

  React.useEffect(() => {
    if (initialized) {
      return;
    }
    initialized = true;

    const userAgent = window.navigator.userAgent;
    const uaParser = new UaParser(userAgent);

    setDeviceInformation({
      os: uaParser.getOS().name ?? '',
      osVersion: uaParser.getOS().version ?? '',
      userAgent,
      browserName: uaParser.getBrowser().name ?? '',
      browserVersion: uaParser.getBrowser().version ?? '',
      isLoaded: true,
      isWebView: userAgent.toLowerCase().indexOf('u-next app') !== -1,
      isIOS: uaParser.getOS().name?.toLowerCase() === 'ios',
      isAndroid: uaParser.getOS().name?.toLowerCase() === 'android',
    });
  }, [setDeviceInformation]);

  return {
    deviceInformation,
  };
};
