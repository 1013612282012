import Link from 'next/link';
import IconAccount from '../../../../../assets/icon/account.svg';
import IconHelp from '../../../../../assets/icon/help.svg';
import IconPoint from '../../../../../assets/icon/point.svg';
import type { Config } from 'u-next/config';

export const DeskTopMenu: React.FC<{
  options: Config;
}> = ({ options }) => (
  <div className="tw-absolute tw-inset-y-auto tw-right-[1rem] tw-h-full">
    <ul className="tw-flex tw-h-full tw-gap-[1.5rem]">
      {[
        {
          url: `${options.MYACCOUNT}/unext-point`,
          text: 'U-NEXTポイント',
          icon: <IconPoint />,
        },
        {
          url: `${options.MYACCOUNT}/settings`,
          text: 'アカウント・契約',
          icon: <IconAccount />,
        },
        {
          url: options.HELP,
          text: 'ヘルプ',
          icon: <IconHelp />,
        },
      ].map(({ url, text, icon }) => (
        <li key={url}>
          <Link href={url} passHref>
            <div className="tw-flex tw-h-full tw-items-center tw-gap-[0.25rem]">
              <div className="tw-h-[1.5rem] tw-w-[1.5rem] [&_svg]:tw-h-full [&_svg]:tw-w-full">
                {icon}
              </div>
              <div className="tw-text-base2Title tw-text-main-100">{text}</div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
