import { Footer } from './Footer';
import { Header } from './Header';
import type { Config } from 'u-next/config';

export const BaseLayout: React.FC<
  React.PropsWithChildren<{
    options: Config;
    hasMenu?: boolean;
  }>
> = ({ children, options, hasMenu = true }) => (
  <div className="tw-grid tw-min-h-full tw-grid-cols-1 tw-grid-rows-[auto,1fr,auto]">
    <div className="tw-sticky tw-top-0 tw-z-[100]">
      <Header options={options} hasMenu={hasMenu} />
    </div>
    <div className="tw-relative tw-pb-[8rem]">{children}</div>
    <div>
      <Footer />
    </div>
  </div>
);
