import React from 'react';
import { useDeviceInformation } from '../../../../hooks/useDeviceInformation';
import { useUserInfoAndMenus } from '../../../../hooks/useUserInfoAndMenus';
import { DeskTopMenu } from './DeskTopMenu';
import { MobileHamburgerButton } from './MobileHamburgerButton';
import { MobileMenuBackGroundFilter } from './MobileMenuBackGroundFilter';
import { MobileMenuNavigation } from './MobileMenuNavigation';
import { SiteLogo } from './SiteLogo';
import type { Config } from 'u-next/config';

export const Header: React.FC<{
  options: Config;
  hasMenu: boolean;
}> = ({ options, hasMenu }) => {
  const { isLoadedUserInfo, userInfo } = useUserInfoAndMenus();
  const { deviceInformation } = useDeviceInformation();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const toggleMenu = React.useCallback(() => {
    setIsExpanded((prevState) => !prevState);
  }, []);

  const canBeShownMenu = hasMenu && isLoadedUserInfo && userInfo.isLoggedIn;

  return !deviceInformation.isWebView ? (
    <div>
      <header className="tw-relative tw-h-[3.5rem] tw-w-full tw-bg-base-100">
        <SiteLogo
          videoUrl={options.VIDEO}
          isYamadaPF={userInfo.isYamadaPF}
          otherFunctionId={userInfo.otherFunctionId}
        />
        {canBeShownMenu && (
          <>
            <div className="tw-hidden laptop:tw-block">
              <DeskTopMenu options={options} />
            </div>
            <div className="tw-block laptop:tw-hidden">
              <MobileHamburgerButton
                isExpanded={isExpanded}
                handleOnClick={toggleMenu}
              />
            </div>
          </>
        )}
        <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-h-[1px] tw-w-full tw-bg-main-10" />
      </header>
      {canBeShownMenu && (
        <div className="tw-relative tw-z-[1000]">
          <MobileMenuBackGroundFilter
            isExpanded={isExpanded}
            handleOnClick={toggleMenu}
          />
          <MobileMenuNavigation isExpanded={isExpanded} options={options} />
        </div>
      )}
    </div>
  ) : null;
};
