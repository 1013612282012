export const MobileHamburgerButton: React.FC<{
  isExpanded: boolean;
  handleOnClick: () => void;
}> = ({ isExpanded, handleOnClick }) => (
  <div
    className="tw-absolute tw-inset-y-0 tw-right-[1rem] tw-z-[1001] tw-my-auto tw-h-[1.5rem] tw-w-[2rem]"
    tabIndex={0}
    role="button"
    onClick={handleOnClick}
  >
    <div
      className={
        isExpanded
          ? 'tw-absolute tw-inset-x-0 tw-top-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-translate-y-[0.57rem] tw-rotate-[-45deg] tw-bg-main-100 tw-transition-transform'
          : 'tw-absolute tw-inset-x-0 tw-top-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-bg-main-100  tw-transition-transform'
      }
    />
    <div
      className={
        isExpanded
          ? 'tw-absolute tw-inset-0 tw-m-auto tw-h-[2px] tw-w-full tw-bg-main-100 tw-opacity-0 tw-transition-opacity'
          : 'tw-absolute tw-inset-0 tw-m-auto tw-h-[2px] tw-w-full tw-bg-main-100 tw-opacity-100 tw-transition-opacity'
      }
    />
    <div
      className={
        isExpanded
          ? 'tw-absolute tw-inset-x-0 tw-bottom-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-translate-y-[-0.57rem] tw-rotate-45 tw-bg-main-100 tw-transition-transform'
          : 'tw-absolute tw-inset-x-0 tw-bottom-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-bg-main-100  tw-transition-transform'
      }
    />
  </div>
);
